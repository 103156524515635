.cardPayX .XIcon::before {
  content: "\f622";
  /* border: 10px solid rgb(255 0 0 / 56%); */
  border-radius: 50%;
  outline: 10px solid rgb(255 80 80 / 34%);
  margin: 10px 0;
}
.cardPayX {
  background-color: #fff;
  box-shadow: 2px 5px 10px #454444;
  border: 3px solid #dc3545 !important;
  position: relative;
  border-radius: 7px;
  /* border-top-right-radius: 30px !important;
  border-bottom-left-radius: 30px !important; */
}
/* .cardPayX::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #dc3545;
  position: absolute;
  left: 0;
  top: -20px;
  box-shadow: 1px 5px 5px #776f6f;
}
.cardPayX::before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #dc3545;
  position: absolute;
  left: 0;
  bottom: -20px;
  box-shadow: 1px 5px 5px #776f6f;
} */

.page {
  height: 100vh;
  background: linear-gradient(
    309deg,
    rgba(10, 233, 148, 1) 0%,
    rgba(179, 102, 255, 1) 19%,
    rgba(216, 228, 228, 1) 49%,
    rgba(0, 244, 122, 1) 100%
  );
}
.cardPay {
  background-color: #fff;
  box-shadow: 2px 5px 10px #454444;
  border: 3px solid #009700ad !important;
  position: relative;
  border-radius: 7px;
  /* border-top-right-radius: 30px !important;
  border-bottom-left-radius: 30px !important; */
}
/* .cardPay::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: green;
  position: absolute;
  left: 0;
  top: -20px;
  box-shadow: 1px 5px 5px #776f6f;
}
.cardPay::before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: green;
  position: absolute;
  left: 0;
  bottom: -20px;
  box-shadow: 1px 5px 5px #776f6f;
} */
.completeIcon {
  position: absolute;
  color: #0e9f0e;
  top: -10px;
  left: 10px;
  font-size: 1.8rem;
}

.cardPay .checkIcon::before {
  content: "\f26a";
  /* border: 10px solid #008000ab ; */
  border-radius: 50%;
  outline: 10px solid #00800047;
  margin: 10px 0;
}
